<template>
    <div class="details">
        <div
            class="title_box"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="name_operation">
                <p>
                    <span>
                        <i class="iconfont icon-wodekehu"></i>
                        销售费用信息
                    </span>
                </p>
                <p>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                        "
                        >返回列表</el-button
                    >
                </p>
            </div>
            <div class="base_info">
                <div>
                    <p>
                        <span>审批编号</span>
                        <span>{{ data.declareNo || '- -' }}</span>
                    </p>
                    <p>
                        <span>申请人</span>
                        <span>{{ data.adminName || '- -' }}</span>
                    </p>
                    <p>
                        <span>费用类型</span>
                        <span>{{
                            data.declareType == 1
                                ? '订单销售费用'
                                : data.declareType == 1
                                ? '月度报销费用'
                                : '- -' || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>申请金额</span>
                        <span>{{ data.amount || '- -' }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>提交时间</span>
                        <span>{{ data.createTime || '- -' }}</span>
                    </p>
                    <p>
                        <span>所在部门</span>
                        <el-tooltip placement="top">
                            <div slot="content">
                                <p
                                    style="line-height: 2"
                                    v-for="item in data.departmentAdminDOList"
                                    :key="item.id"
                                >
                                    {{ item.departmentName }}
                                </p>
                            </div>
                            <span class="tetx">
                                {{ returnName(data) }}
                            </span>
                        </el-tooltip>
                        <!-- <span>{{ returnName(data) || '- -' }}</span> -->
                    </p>
                    <p>
                        <span>客户</span>
                        <span>{{
                            data.customer
                                ? data.customer.companyName
                                : '- -' || '- -'
                        }}</span>
                    </p>
                    <p>
                        <span></span>
                        <span></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="pay_info" v-for="item in businessInfoList" :key="item.id">
            <div class="name_operation">
                <p>
                    <span>
                        <i
                            class="iconfont icon-pay-order"
                            style="color: #ffbd27"
                        ></i>
                        支付信息
                    </span>
                </p>
                <p></p>
            </div>
            <div class="base_info">
                <div>
                    <p>
                        <span>姓名</span>
                        <span>{{ item.nickName || '- -' }}</span>
                    </p>
                    <p>
                        <span>邮箱</span>
                        <span>{{ item.email || '- -' }}</span>
                    </p>
                    <p>
                        <span>银行信息</span>
                        <span>{{ item.bankNo || '- -' }}</span>
                    </p>
                    <p style="position: relative">
                        <span>地址</span>
                        <span class="address">{{ item.address || '- -' }}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <span>手机号</span>
                        <span>{{ item.phone || '- -' }}</span>
                    </p>
                    <p>
                        <span>支付宝</span>
                        <span>{{ item.alipay || '- -' }}</span>
                    </p>
                    <p>
                        <span></span>
                        <span>{{ item.bankName || '- -' }}</span>
                    </p>
                    <p>
                        <span></span>
                        <span></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="base_info">
            <el-form
                ref="form"
                style="flex: 1;
                    margin-top: 20px;
                    margin-left: 40px;
                    margin-bottom: 80px;
                "
                label-position="left" :model="data"  label-width="100px"
            >
            <el-form-item label="实际支付金额">
                    <el-input
                        v-model="data.actualPayAmount"
                        size="small"
                        style="width: 270px"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-input
                        v-model="data.payStr"
                        style="width: 270px"
                        size="small"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="支付时间" required style="">
                    <el-date-picker
                        v-model="data.payTime"
                        style="width: 270px"
                        size="small"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="bnt">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #333;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >提交</el-button
            >
        </div>
    </div>
</template>
<script>
import {
    declareGet,
    businessInfoList,
    declareUpdate,
} from '@/api/cost/declare.js';
export default {
    components: {},
    data() {
        return {
            data: {},
            businessInfoList: [],
        };
    },
    created() {},
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            let obj = {
                param: {
                    id: this.$route.query.id,
                },
            };
            declareGet(obj).then((res) => {
                this.data = res.data;
                let data = {
                    param: {
                        customerId: res.data.customerId,
                        status: 1,
                        adminId: res.data.adminId,
                    },
                };
                businessInfoList(data).then((result) => {
                    if (result.code == 200) {
                        this.businessInfoList = result.data.list;
                    }
                });
            });
        },
        onClose() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 4,
                },
            };
            declareUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$router.go(-1);
                }
            });
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 11,
                    actualPayAmount: this.data.actualPayAmount,
                    payTime: this.data.payTime,
                    payStr: this.data.payStr,

                },
            };
            declareUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$router.go(-1);
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 55px;
    box-sizing: border-box;
    overflow-y: auto;
    .title_box {
        padding: 16px 20px;
        background: #fff;
    }
    .base_info {
        font-size: 14px;

        > div {
            display: flex;
            margin-top: 16px;
            padding-left: 35px;

            > p {
                flex: 1;
                span:nth-child(1) {
                    color: #666666;
                    display: inline-block;
                    width: 65px;
                }
                span:nth-child(2) {
                    color: #333333;
                    font-weight: 600;
                    margin-right: 10px;
                }
            }
        }
    }
    .pay_info {
        background-color: #fff;
        margin-top: 12px;
        border-radius: 4px;
        padding: 16px 20px;
    }
    .bnt {
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 14px 0;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        button {
            padding: 5px 30px;
        }
    }
    .name_operation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        p {
            i {
                color: #2370eb;
                margin-right: 6px;
                font-weight: normal;
            }
            span {
                font-size: 14px;
                font-weight: 600;
            }
        }
        .el-button--primary.is-disabled {
            background-color: #fff;
            border-color: #ebeef5;
            color: #c0c4cc;
        }
        .el-button--primary {
            background-color: #2370eb;
        }
    }
}
.address {
    position: absolute;
    left: 67px;
    top: 1px;
}
</style>
